
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ScrollComponent } from '@/assets/ts/components/_ScrollComponent';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
import MainMenuConfig from './Menu';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'kt-menu',
  data() {
    return {
      MainMenuConfig: [
        {
          pages: [
            {
              heading: 'Certification Summary',
              route: '/certificate/certificate-dashboard',
              fontIcon: 'fa-certificate',
            },
            {
              heading: 'Search',
              route: '/certificate/search',
              fontIcon: 'fa-certificate',
            },
            {
              heading: 'Training Certificate',
              route: '/certificate/training-certificate',
              fontIcon: 'fa-certificate',
            },

            {
              heading: 'Training Certificate Template List',
              route: '/certificate/training-certificate-list',
              fontIcon: 'fa-list',
            },
            {
              heading: 'Training Certificate Information',
              route: '/certificate/training-certificate-Information',
              fontIcon: 'fa-info',
            },
            {
              heading: "Training Certificate Configuration",
              route: "/certificate/training-certificate-configuration",
              fontIcon: "fa-certificate",
            },

            {
              heading: "Training Certificate Download",
              route: "/certificate/training-certificate-download",
              fontIcon: "fa-certificate",
            },
            {
              heading: 'Advance Search',
              route: '/certificate/training-certificate-advance-search',
              fontIcon: 'fa-certificate',
            },
          ],
        },
      ],
    };
  },
  components: {},
  async created() {
    await this.actioncheck();
    await this.menuCheck();
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].heading == 'Batch') {
            let actions = menu[i].action;
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Create') {
                this.MainMenuConfig = [
                  {
                    pages: [
                      {
                        heading: 'Certification Summary',
                        route: '/certificate/certificate-dashboard',
                        fontIcon: 'fa-certificate',
                      },
                      // {
                      //   heading: "Search",
                      //   route: "/certificate/search",
                      //   fontIcon: "fa-certificate",
                      // },
                      {
                        heading: 'Training Certificate',
                        route: '/certificate/training-certificate',
                        fontIcon: 'fa-certificate',
                      },
                      {
                        heading: 'Training Certificate Information',
                        route: '/certificate/training-certificate-Information',
                        fontIcon: 'fa-info',
                      },
                      {
                        heading: "Training Certificate Configuration",
                        route: "/certificate/training-certificate-configuration",
                        fontIcon: "fa-certificate",
                      },

                      {
                        heading: "Training Certificate Download",
                        route: "/certificate/training-certificate-download",
                        fontIcon: "fa-certificate",
                      },
                      {
                        heading: 'Advance Search',
                        route:
                          '/certificate/training-certificate-advance-search',
                        fontIcon: 'fa-certificate',
                      },
                    ],
                  },
                ];
              }
            }
          }
        }
      }
    },
    async menuCheck() {
      let seip_role_id = VueCookieNext.getCookie('_seip_role_id');
      if (seip_role_id == 1) {
        this.MainMenuConfig = [
          {
            pages: [
              {
                heading: 'Certification Summary',
                route: '/certificate/certificate-dashboard',
                fontIcon: 'fa-certificate',
              },
              // {
              //   heading: "Search",
              //   route: "/certificate/search",
              //   fontIcon: "fa-certificate",
              // },
              {
                heading: 'Training Certificate',
                route: '/certificate/training-certificate',
                fontIcon: 'fa-certificate',
              },

              {
                heading: 'Training Certificate Template List',
                route: '/certificate/training-certificate-list',
                fontIcon: 'fa-list',
              },
              {
                heading: 'Training Certificate Information',
                route: '/certificate/training-certificate-Information',
                fontIcon: 'fa-info',
              },
              {
                heading: "Training Certificate Configuration",
                route: "/certificate/training-certificate-configuration",
                fontIcon: "fa-certificate",
              },

              {
                heading: "Training Certificate Download",
                route: "/certificate/training-certificate-download",
                fontIcon: "fa-certificate",
              },
              {
                heading: 'Advance Search',
                route: '/certificate/training-certificate-advance-search',
                fontIcon: 'fa-certificate',
              },
            ],
          },
        ];
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
